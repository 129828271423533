<template>
    <div>
        <ts-page-title
            :title="$t('citizenshipStatistic.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('citizenshipStatistic.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <div class="tw-space-x-2">
                    <Button
                        type="success"
                        ghost
                        @click.prevent="exportExcel"
                        :loading="exporting"
                    >
                        <i class="fas fa-file-excel"></i> {{ $t('export') }}
                    </Button>
                    <Button type="info" ghost @click.prevent="importExcel"
                        ><i class="fas fa-file-import"></i> {{ $t('import') }}
                    </Button>
                </div>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('citizenshipStatistic.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="province">
                    {{
                        locale == 'kh'
                            ? row.province_name_kh
                            : row.province_name_en
                    }}
                </template>
                <template slot-scope="{ row }" slot="district">
                    {{
                        locale == 'kh'
                            ? row.district_name_kh
                            : row.district_name_en
                    }}
                </template>
                <template slot-scope="{ row }" slot="commune">
                    {{
                        locale == 'kh'
                            ? row.commune_name_kh
                            : row.commune_name_en
                    }}
                </template>
                <template slot-scope="{ row }" slot="commune_chief_name">
                    {{ row.commune_chief_name }}
                </template>
                <template slot-scope="{ row }" slot="commune_phone_number">
                    {{ row.commune_phone_number }}
                </template>
                <template slot-scope="{ row }" slot="village">
                    {{
                        locale == 'kh'
                            ? row.village_name_kh
                            : row.village_name_en
                    }}
                </template>
                <template slot-scope="{ row }" slot="village_chief_name">
                    {{ row.village_chief_name }}
                </template>
                <template slot-scope="{ row }" slot="village_phone_number">
                    {{ row.village_phone_number }}
                </template>
                <template slot-scope="{ row }" slot="number_of_family">
                    {{ row.number_of_family }}
                </template>
                <template slot-scope="{ row }" slot="total_population">
                    {{ row.total_male + row.total_female }}
                </template>
                <template slot-scope="{ row }" slot="total_male">
                    {{ row.total_male }}
                </template>
                <template slot-scope="{ row }" slot="total_female">
                    {{ row.total_female }}
                </template>
            </Table>

            <Modal
                v-model="showFormImport"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                :title="$t('citizenshipStatistic.importCitizenshipStatistic')"
            >
                <ImportCitzenshipStatic
                    ref="import_allowance"
                    @cancel="() => (showFormImport = false)"
                    @fetchData="fetchData"
                />
            </Modal>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="
                        fetchData({
                            filter: {
                                has_contract: has_contract
                            }
                        })
                    "
                ></ts-i-pagination>
            </div>
        </ts-panel>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { debounce } from 'lodash'
import { Errors } from 'form-backend-validation'
import * as FileDownload from 'downloadjs'
import { trim } from 'lodash'
import { orderBy } from 'lodash'
import ImportCitzenshipStatic from './import-citizenship-statistic.vue'
export default {
    name: 'citizenshipStatisticTable',
    components: {
        ImportCitzenshipStatic
    },
    data () {
        return {
            loading: false,
            exporting: false,
            showFormImport: false,
            width: 'width: 300px',
            errors: new Errors(),
            province_code: [],
            district_code: [],
            commune_code: []
        }
    },
    computed: {
        ...mapState('corporate/citizenshipStatistic', [
            'resources',
            'pagination',
            'provinces',
            'districts',
            'communes'
        ]),
        search: {
            get () {
                return this.$store.state.corporate.citizenshipStatistic.search
            },
            set (newValue) {
                this.$store.commit(
                    'corporate/citizenshipStatistic/SET_SEARCH',
                    newValue
                )
                this.$store.commit(
                    'corporate/citizenshipStatistic/RESET_CURRENT_PAGE'
                )
            }
        },
        locale () {
            return this.$root.$i18n.locale
        },
        columns () {
            return [
                {
                    title: this.$t('citizenshipStatistic.provinceCode'),
                    slot: 'province',
                    minWidth: 220,
                    sortable: true,
                    fixed: 'left',
                    filters: orderBy(this.provinces, [
                        'province_code',
                        'name_en'
                    ]).map(el => ({
                        value: el.province_code,
                        label: this.locale == 'kh' ? el.name_kh : el.name_en
                    })),
                    filterRemote (province_code) {
                        this.province_code = province_code
                    }
                },
                {
                    title: this.$t('citizenshipStatistic.districtCode'),
                    slot: 'district',
                    minWidth: 150,
                    sortable: true,
                    fixed: 'left',
                    filters: orderBy(this.districts, [
                        'district_code',
                        'name_en'
                    ]).map(el => ({
                        value: el.district_code,
                        label: this.locale == 'kh' ? el.name_kh : el.name_en
                    })),
                    filterMultiple: true,
                    filterRemote (district_code) {
                        this.district_code = district_code
                    }
                },
                {
                    title: this.$t('citizenshipStatistic.communeCode'),
                    slot: 'commune',
                    minWidth: 150,
                    sortable: true,
                    fixed: 'left',
                    filters: orderBy(this.communes, [
                        'commune_code',
                        'name_en'
                    ]).map(el => ({
                        value: el.commune_code,
                        label: this.locale == 'kh' ? el.name_kh : el.name_en
                    })),
                    filterMultiple: true,
                    filterRemote (commune_code) {
                        this.commune_code = commune_code
                    }
                },
                {
                    title: this.$t('citizenshipStatistic.communeChiefName'),
                    slot: 'commune_chief_name',
                    minWidth: 150,
                    sortable: true
                },
                {
                    title: this.$t('citizenshipStatistic.phoneNumber'),
                    slot: 'commune_phone_number',
                    minWidth: 130,
                    align: 'center',
                    sortable: true
                },
                {
                    title: this.$t('citizenshipStatistic.villageCode'),
                    slot: 'village',
                    minWidth: 160,
                    sortable: true
                },
                {
                    title: this.$t('citizenshipStatistic.villageChiefName'),
                    slot: 'village_chief_name',
                    align: 'center',
                    minWidth: 130
                },
                {
                    title: this.$t('citizenshipStatistic.phoneNumber'),
                    slot: 'village_phone_number',
                    minWidth: 130,
                    sortable: true
                },
                {
                    title: this.$t('citizenshipStatistic.numberOfFamily'),
                    slot: 'total_male',
                    minWidth: 130,
                    sortable: true,
                    align: 'center'
                },
                {
                    title: this.$t('citizenshipStatistic.totalPopulation'),
                    slot: 'total_male',
                    minWidth: 160,
                    sortable: true,
                    align: 'center'
                },
                {
                    title: this.$t('citizenshipStatistic.totalMale'),
                    slot: 'total_male',
                    minWidth: 130,
                    sortable: true,
                    align: 'center'
                },
                {
                    title: this.$t('citizenshipStatistic.totalFemale'),
                    slot: 'total_female',
                    minWidth: 130,
                    sortable: true,
                    align: 'center'
                }
            ]
        }
    },
    methods: {
        ...mapActions('corporate/citizenshipStatistic', [
            'getProvince',
            'getDistrict',
            'getCommune'
        ]),
        async fetchResource () {
            this.loading = true
            await this.getProvince()
            this.loading = false
        },
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('corporate/citizenshipStatistic/fetch', {
                    province_code: this.province_code,
                    district_code: this.district_code,
                    commune_code: this.commune_code,
                    village_code: this.village_code,
                    ...attributes
                })
                .catch(error => {
                    this.loading = false
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        exportExcel () {
            this.errors = new Errors()
            this.exporting = true
            this.$store
                .dispatch('corporate/citizenshipStatistic/exportExcel')
                .then(response => {
                    let filename = trim(
                        response.headers['content-disposition']
                            .substring(
                                response.headers['content-disposition'].indexOf(
                                    'filename'
                                )
                            )
                            .replace('filename=', ''),
                        '"'
                    )
                    FileDownload(response.data, filename)
                })
                .catch(err => {
                    let error = JSON.parse(
                        String.fromCharCode.apply(null, new Uint8Array(err))
                    )
                    this.notice({ type: 'error', text: error.message })
                    this.errors = new Errors(error.errors)
                })
                .finally(() => (this.exporting = false))
        },
        importExcel () {
            this.showFormImport = true
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'CITIZENSHIP STATISTIC',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({ search })
        }, 500),
        province_code: function (value) {
            if (value.length > 0) {
                this.getDistrict(value)
            }
            this.district_code = []
            this.commune_code = []
            this.fetchData()
        },
        district_code: function (value) {
            if (value.length > 0) {
                this.getCommune(value)
            }
            this.commune_code = []
            this.fetchData()
        },
        commune_code: function () {
            this.fetchData()
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchResource()
            vm.fetchData()
        })
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('corporate/citizenshipStatistic/RESET_STATE')
        next()
    }
}
</script>
